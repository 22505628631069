import { ChangeEvent, FocusEvent, FC } from 'react';
import { Input, InputStyle } from '../form-control/Input';
import Button, { ButtonType } from './Button';

type CompoundProps = {
  id?: string;
  dataCy: string;
  className?: string;
  value: string;
  placeholder?: string;
  buttonText?: string;
  buttonDisabled?: boolean;
  autocomplete?: string;
  onClick: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const CompoundInput: FC<CompoundProps> = (props) => {
  const {
    id,
    dataCy,
    className,
    value,
    onClick,
    onChange,
    buttonText = 'Add',
    buttonDisabled,
    placeholder,
    autocomplete,
    onFocus,
    onBlur,
    disabled,
  } = props;

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    onClick();
  };

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !buttonDisabled) {
      onClick();
    }
  };

  return (
    <div className={`rounded-dpm-sm flex items-center justify-between ${className}`} data-testid="compound-input">
      <Input
        data-cy={dataCy}
        onFocus={onFocus}
        onBlur={onBlur}
        id={id}
        className="m-0 w-full rounded-r-none"
        placeholder={placeholder}
        autocomplete={autocomplete}
        style={InputStyle.COMPOUND}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        disabled={disabled}
      >
        <Input.Slot name="trailing">
          <Button
            data-cy={`${dataCy}-button`}
            className="-mr-[2px] flex-shrink-0 rounded-l-none leading-6 hover:scale-100"
            type={ButtonType.PRIMARY}
            onClick={handleClick}
            disabled={buttonDisabled || disabled}
            ignoreMinWidth={true}
          >
            {buttonText}
          </Button>
        </Input.Slot>
      </Input>
    </div>
  );
};

export default CompoundInput;
